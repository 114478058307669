import request from '@/utils/request'


// 查询用户动态浏览列表
export function listDynamicBrowse(query) {
  return request({
    url: '/user/user-dynamic-browse/list',
    method: 'get',
    params: query
  })
}

// 查询用户动态浏览分页
export function pageDynamicBrowse(query) {
  return request({
    url: '/user/user-dynamic-browse/page',
    method: 'get',
    params: query
  })
}

// 查询用户动态浏览详细
export function getDynamicBrowse(data) {
  return request({
    url: '/user/user-dynamic-browse/detail',
    method: 'get',
    params: data
  })
}

// 新增用户动态浏览
export function addDynamicBrowse(data) {
  return request({
    url: '/user/user-dynamic-browse/add',
    method: 'post',
    data: data
  })
}

// 修改用户动态浏览
export function updateDynamicBrowse(data) {
  return request({
    url: '/user/user-dynamic-browse/edit',
    method: 'post',
    data: data
  })
}

// 删除用户动态浏览
export function delDynamicBrowse(data) {
  return request({
    url: '/user/user-dynamic-browse/delete',
    method: 'post',
    data: data
  })
}
